import { Container, Grid, Typography } from "@mui/material";

const Footer = () => {
  return (
    <footer className="footer">
      <Container maxWidth="lg">
        <Grid
          container
          rowSpacing={2}
          sx={{
            justifyContent: { xs: "center", sm: "space-between" },
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            lg={12}
            sx={{ textAlign: "center", marginTop: "10px" }}
          >
            <a href="https://whoflies.ru">
              <img
                src="./images/logo_footer.png"
                alt="Whoflies logo"
                width="148"
                height="48"
              ></img>
            </a>
          </Grid>
          <Grid item lg={12} xs={10} align="center">
            <Typography variant="body3">
              Отправляй посылки с попутчиками и экономь в путешествиях.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{ textAlign: "center", marginBottom: "20px" }}
          >
            <Typography variant="body3">
              © Whoflies {new Date().getFullYear()}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
