import { Container, Grid, Typography } from "@mui/material";
import {
  FaDoorOpen,
  FaCoins,
  FaPeopleGroup,
  FaEarthAsia,
} from "react-icons/fa6";

const AboutApp = () => {
  return (
    <div id="about-whoflies" className="aboutapp-container">
      <Container
        maxWidth="lg"
        sx={{
          marginTop: { xs: "0px", md: "50px", lg: "50px" },
        }}
      >
        <Typography variant="h2">О приложении</Typography>
        <Grid container spacing={6} justifyContent="center">
          <Grid item lg={3} xs={10} align="center">
            <FaDoorOpen size={60} color="#5DB075" />
            <Typography variant="h3">Без регистрации</Typography>
            <Typography variant="body3">
              Не нужно регистрироваться, просто запусти приложение в Телеграм
            </Typography>
          </Grid>
          <Grid item lg={3} xs={10} align="center">
            <FaCoins size={60} color="#5DB075" />
            <Typography variant="h3">Бесплатно</Typography>
            <Typography variant="body3">
              Приложение абсолютно бесплатное и не требует взносов
            </Typography>
          </Grid>
          <Grid item lg={3} xs={10} align="center">
            <FaPeopleGroup size={60} color="#5DB075" />
            <Typography variant="h3">Без посредников</Typography>
            <Typography variant="body3">
              Связывайся с путешественниками или заказчиками напрямую
            </Typography>
          </Grid>
          <Grid item lg={3} xs={10} align="center">
            <FaEarthAsia size={60} color="#5DB075" />
            <Typography variant="h3">Без границ</Typography>
            <Typography variant="body3">
              Владивосток или Новая Гвинея - неважно, можно выбрать любую
              локацию
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          sx={{ paddingTop: "35px" }}
        >
          <Grid item lg={5} md={5} xs={10}>
            <div className="appDescriptionBox">
              <Typography variant="h3">Для путешественников</Typography>
              <Typography variant="body3">
                Куда бы вы не планировали путешествие, теперь у вас есть
                возможность помочь с доставкой посылок или документов и немного
                подзаработать - вы сами обсуждаете условия с заказчиком и
                устанавливаете цену.
              </Typography>
            </div>
          </Grid>
          <Grid item lg={5} md={5} sm={10} xs={10}>
            <div className="appDescriptionBox">
              <Typography variant="h3">Для отправителей</Typography>
              <Typography variant="body3">
                Потребовались документы из дома или нужно отправить подарок
                близким на праздник - находите путешественников следующих по
                нужному маршруту и договаривайтесь об условиях перевозки или
                создайте новый заказ.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AboutApp;
