import { createTheme } from "@mui/material";

const theme = createTheme();

export const themeOptions = createTheme({
  palette: {
    type: "main",
    primary: {
      main: "#5DB075",
    },
    secondary: {
      main: "#5DB075",
    },
    background: {
      default: "#FFFFFF",
    },
    text: {
      secondary: "#676767",
      primary: "#3F3F3F",
      disabled: "#DCDCDC",
    },
  },
  typography: {
    fontFamily: '"Epilogue" , sans-serif',
    h1: {
      fontSize: 40,
      fontWeight: 700,
      color: '#5DB075',
      lineHeight: 1.1,
      [theme.breakpoints.down('md')]: {
        fontSize: 30,
      },
    },
    h2: {
      fontSize: 36,
      fontWeight: 700,
      color: '#5DB075',
      paddingTop: '35px',
      paddingBottom: '25px',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: 30,
      },
    },
    h3: {
      fontSize: 24,
      fontWeight: 700,
      color: '#5DB075',
      alignItems: 'center',
      textAlign: 'center',
      paddingBottom: '10px',
    },
    h4: {
      fontWeight: 700,
      fontSize: 16,
      color: "#3F3F3F",
    },
    body1: {
      fontSize: 20,
      color: "#3F3F3F",
    },
    body2: {
      fontSize: 12,
      lineHeight: 1.62,
      color: "#696969",
    },
    body3: {
      fontSize: 16,
      color: "#3F3F3F",
    },
    button: {
      fontSize: 16,
      fontWeight: 700,
    },
  },
  shape: {
    borderRadius: 15,
  },
});