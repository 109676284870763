import { Container, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";

const handleStartBotButton = () => {
  const url = "https://t.me/whoflies_bot";
  window.open(url, "_blank");
};

const TopContainer = () => {
  return (
    <div className="top-container">
      <Container
        maxWidth="lg"
        sx={{ marginBottom: { lg: "0px", md: "0px", xs: "0px" } }}
      >
        <Grid container>
          <Grid
            item
            lg={7}
            md={6}
            sm={12}
            xs={12}
            sx={{
              marginBottom: { xs: "20px", sm: "20px", md: "20px" },
              padding: { md: "20px 0", sm: "20px 0" },
              paddingTop: { xs: "20px" },
              paddingRight: { md: "20px" },
              textAlign: { xs: "center", md: "left", lg: "left" },
            }}
          >
            <Typography
              variant="h1"
              sx={{
                paddingBottom: { xs: "20px" },
              }}
            >
              Приложение для доставки посылок с путешественниками
              <br />
            </Typography>
            <div>
              <Grid
                item
                xs={12}
                sx={{
                  display: { xs: "block", md: "none", lg: "none" },
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="./images/screenshot.png"
                    alt="List of trips"
                    style={{
                      width: "220px",
                      height: "421px",
                      paddingBottom: "20px",
                    }}
                  />
                </div>
              </Grid>
              <Typography variant="body1">
                Больше не нужно искать путешественников и заказы в десятках
                чатов и групп — всё собрано в одном приложении.{" "}
              </Typography>
              <br />
              <Typography variant="body1">
                Отправляй посылки с попутчиками и путешествуй экономно.{" "}
              </Typography>
            </div>
            <Button
              onClick={handleStartBotButton}
              sx={{
                border: "1px solid #5DB075",
                p: 1,
                borderRadius: "10px",
                textTransform: "none",
                marginTop: "25px",
                marginBottom: "10px",
                backgroundColor: "#5DB075",
                alignItems: { xs: "center", md: "left", lg: "left" },
                "&:hover": {
                  backgroundColor: "#3E854F",
                },
              }}
            >
              <img
                src="./images/telegram_white_nobackground.png"
                alt="icon"
                style={{ width: "22px", height: "18px", marginRight: "8px" }}
              />
              <Typography
                style={{
                  textTransform: "none",
                  fontSize: "18px",
                  color: "#FFFFFF",
                }}
              >
                Запустить приложение
              </Typography>
            </Button>
            <Typography variant="body2">
              Не требуется регистрация и авторизация, <br />достаточно иметь Telegram.{" "}
            </Typography>
          </Grid>
          <Grid
            item
            md={5}
            xs={12}
            sx={{
              display: { xs: "none", md: "block" },
              marginTop: "40px",
              marginLeft: "auto",
              position: "relative",
              overflow: "visible",
              zIndex: 1,
              marginBottom: "-50px",
            }}
          >
            <div
              style={{
                position: "absolute",
                right: "0px",
                top: "0px",
                bottom: 0,
                display: "flex",
                flexDirection: "row",
                alignItems: "right",
              }}
            >
              <img
                src="./images/screenshot.png"
                alt="List of trips"
                style={{ width: "220px", height: "421px", marginRight: "25px" }}
              />
              <img
                src="./images/screenshot2.png"
                alt="Order request"
                style={{ width: "220px", height: "421px" }}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default TopContainer;
