import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

const pages = [
  { name: "О приложении", url: "#about-whoflies" },
  { name: "Как это работает?", url: "#how-whoflies-works" },
  { name: "Частые вопросы", url: "#questions" },
  { name: "Наши контакты", url: "#whoflies-contacts" },
];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleStartBotButton = () => {
    const url = "https://t.me/whoflies_bot";
    window.open(url, "_blank");
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "#FFFFFF" }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box
            component="a"
            href="https://whoflies.ru"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
            }}
          >
            <img
              src="./images/logo.png"
              alt="Whoflies logo"
              width="148"
              height="50"
            ></img>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="main menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <a href={page.url} className="no-decoration">
                    <Typography textAlign="center" >{page.name}</Typography>
                  </a>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            variant="h5"
            component="a"
            href="https://whoflies.ru"
            sx={{
              my: 1.5,
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
            }}
          >
            <img
              src="./images/logo.png"
              alt="Whoflies logo"
              width="148"
              height="50"
            ></img>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
            }}
          >
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => {
                  window.location.href = page.url;
                }}
                sx={{
                  my: 2,
                  color: "#000",
                  textTransform: "none",
                  fontWeight: "normal",
                  display: "block",
                }}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0, marginLeft: { xs: "0px", sm: "15px" } }}>
            <Button
              onClick={handleStartBotButton}
              sx={{
                display: { xs: "none", sm: "flex" },
                alignItems: "center",
                border: "1px solid #26A4E3",
                p: 1,
                borderRadius: "10px",
                textTransform: "none",
              }}
            >
              <img
                src="./images/telegram_blue_nobackground.png"
                alt="icon"
                style={{ width: "22px", height: "18px", marginRight: "8px" }}
              />
              <Typography
                style={{
                  color: "#000",
                  textTransform: "none",
                  fontSize: "18px",
                }}
              >
                Запустить приложение
              </Typography>
            </Button>
            <Box
              sx={{ display: { xs: "flex", sm: "none" }, alignItems: "center" }}
            >
              <a
                href="https://t.me/whoflies_bot"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: "block",
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #26A4E3",
                }}
              >
                <img
                  src="./images/telegram_blue_nobackground.png"
                  alt="icon"
                  style={{
                    width: "22px",
                    height: "18px",
                    marginTop: "12px",
                    marginLeft: "7px",
                  }}
                />
              </a>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
