import { Container, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import {
  FaSquarePhone,
  FaHandshakeSimple,
  FaRegCircleQuestion,
} from "react-icons/fa6";

const handleSupportButton = () => {
  const url = "https://t.me/ramified";
  window.open(url, "_blank");
};

const handleCooperateButton = () => {
  const url = "https://t.me/ramified";
  window.open(url, "_blank");
};

const handleQuestionsButton = () => {
  const url = "https://t.me/ramified";
  window.open(url, "_blank");
};

const handleSubscribeButton = () => {
  const url = "https://t.me/whoflies_official";
  window.open(url, "_blank");
};

const ContactUs = () => {
  return (
    <div id="whoflies-contacts" className="contactus-container">
      <Container maxWidth="lg" sx={{ backgroundColor: "#FFFFFF" }}>
        <Typography variant="h2" align="center" gutterBottom>
          Как с нами связаться?
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          <Grid item lg={4} md={4} xs={10}>
            <div className="contactus-card">
              <FaSquarePhone size={30} color="#5DB075" />
              <Typography variant="h3">Поддержка</Typography>
              <Typography variant="body3" align="center">
                Если у вас возникли проблемы с приложением, напишите нам, мы
                обязательно всё поправим.
              </Typography>
              <br />

              <Button
                onClick={handleSupportButton}
                sx={{
                  border: "1px solid #5DB075",
                  p: 1,
                  borderRadius: "10px",
                  textTransform: "none",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              >
                <img
                  src="./images/telegram_blue_nobackground.png"
                  alt="icon"
                  style={{ width: "22px", height: "18px", marginRight: "8px" }}
                />
                <Typography
                  style={{
                    textTransform: "none",
                    fontSize: "14px",
                    color: "#3F3F3F",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  Сообщить о проблеме
                </Typography>
              </Button>
            </div>
          </Grid>
          <Grid item lg={4} md={4} xs={10}>
            <div className="contactus-card">
              <FaHandshakeSimple size={30} color="#5DB075" />
              <Typography variant="h3">Сотрудничество</Typography>
              <Typography variant="body3" align="center">
                Если вы автор блога/канала или у вас просто есть классные идеи -
                будем рады пообщаться.
              </Typography>
              <br />

              <Button
                onClick={handleCooperateButton}
                sx={{
                  border: "1px solid #5DB075",
                  p: 1,
                  borderRadius: "10px",
                  textTransform: "none",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              >
                <img
                  src="./images/telegram_blue_nobackground.png"
                  alt="icon"
                  style={{ width: "22px", height: "18px", marginRight: "8px" }}
                />
                <Typography
                  style={{
                    textTransform: "none",
                    fontSize: "14px",
                    color: "#3F3F3F",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  Написать нам
                </Typography>
              </Button>
            </div>
          </Grid>
          <Grid item lg={4} md={4} xs={10}>
            <div className="contactus-card">
              <FaRegCircleQuestion size={30} color="#5DB075" />
              <Typography variant="h3">Другие вопросы</Typography>
              <Typography variant="body3" align="center">
                Будем рады помочь/ответить на любые другие вопросы, пишите - мы
                обязательно ответим.
              </Typography>
              <br />

              <Button
                onClick={handleQuestionsButton}
                sx={{
                  border: "1px solid #5DB075",
                  p: 1,
                  borderRadius: "10px",
                  textTransform: "none",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              >
                <img
                  src="./images/telegram_blue_nobackground.png"
                  alt="icon"
                  style={{ width: "22px", height: "18px", marginRight: "8px" }}
                />
                <Typography
                  style={{
                    textTransform: "none",
                    fontSize: "14px",
                    color: "#3F3F3F",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  Отправить сообщение
                </Typography>
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3} justifyContent="center" style={{ paddingTop: "40px"}}>
          <Grid item lg={8} xs={8} align="center">
            <Typography variant="h3">Новости приложения</Typography>
            <Typography variant="body3">
              Мы активно развиваем сервис и добавляем новые фичи, подписывайся на наш канал и будь в курсе последних новостей ;)
            </Typography>
            <br />
            <Button
                onClick={handleSubscribeButton}
                sx={{
                  border: "1px solid #5DB075",
                  p: 1,
                  borderRadius: "10px",
                  textTransform: "none",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              >
                <img
                  src="./images/telegram_blue_nobackground.png"
                  alt="icon"
                  style={{ width: "22px", height: "18px", marginRight: "8px" }}
                />
                <Typography
                  style={{
                    textTransform: "none",
                    fontSize: "14px",
                    color: "#3F3F3F",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  Подписаться на новости
                </Typography>
              </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ContactUs;
