
import './App.css';
import { themeOptions } from "./theme";
import { ThemeProvider } from "@mui/material";
import NavBar from "./components/navbar.component";
import TopContainer from "./components/top-container.component";
import AboutApp from "./components/aboutapp.component";
import HowItWorks from "./components/howitworks.component";
import Questions from "./components/questions.component";
import ContactUs from "./components/contactus.component";
import Footer from "./components/footer.component";
import ReactGA from "react-ga4";

ReactGA.initialize("G-SFTFKQ0E1W");

function App() {
  return (
    <>
      <ThemeProvider theme={themeOptions}>
        <NavBar />
        <TopContainer />
        <AboutApp />
        <HowItWorks />
        <Questions />
        <ContactUs />
        <Footer />
      </ThemeProvider>
    </>
  );
}

export default App;
