import * as React from "react";
import { Container, Grid, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Questions() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div id="questions" className="questions-container">
      <Container maxWidth="md">
        <Typography variant="h2">Вопросы и ответы</Typography>
        <Box sx={{ width: "100%", alignItems: "center" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              centered
            >
              <Tab label="Отправителям" {...a11yProps(0)} />
              <Tab label="Путешественникам" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4">
                  Как я могу отправить посылку или документы попутчиком?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body3" align="center">
                  Вам необходимо найти и выбрать попутчика для отправки
                  документов в приложении и договориться обо всём напрямую в
                  приложении Telegram.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4">
                  Что я могу отправить с помощью приложения Whoflies?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body3" align="center">
                  Вы можете отправить всё, что не запрещено правилами перевозки
                  авиакомпаний, локальным законодательством странами вывоза и
                  ввоза. Во избежание недоразумений рекомендуем внимательно
                  ознакомиться со всей информацией перед отправкой посылки.{" "}
                  <br />
                  <br />
                  Также стоит понимать, что если документы согласится перевезти
                  едва ли не любой путешественник, то с габаритным грузом будет
                  немного сложнее. Возможно вам потребуется оплатить багажное
                  место и обговорить другие условия.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4">
                  Как мне выбрать путешественника для отправки посылки или
                  документов?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body3" align="center">
                  Мы рекомендуем списаться, а лучше созвониться и обговорить все
                  нюансы, а также попросить ссылки на социальные сети, такие как
                  Facebook, Linkedin, VK и другие, и убедиться, что они в
                  действительности принадлежат путешественнику написав напрямую
                  в социальную сеть. Это поможет удостовериться, что человек
                  существует и решить, стоит ли доверять ему перевозку.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4">
                  Нужна ли предоплата при отправке посылок и документов
                  попутчиками?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body3" align="center">
                  Ни в коем случае не соглашайтесь на предоплату и не переводите
                  деньги до встречи с человеком, а в идеале до факта передачи
                  посылки из рук в руки. В последнее время участились случаи
                  мошенничества.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4">
                  Каковы шансы, что меня не обманут?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body3" align="center">
                  К сожалению, по опыту других приложений и групп в различных
                  социальных сетях, случаи обмана имеют место быть. Приложение
                  Whoflies не выступает гарантом (пока что) и вся
                  ответственность за передачу и доставку посылок и документов
                  полностью ложится на вас. Просим ответственно подойти к выбору
                  и знакомству с путешественником, пообщаться, уточнить все
                  детали и только потом запускать процесс передачи посылки или
                  документов.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4">
                  Сколько обычно обходится доставка посылки попутчиком?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body3" align="center">
                  Вы сами договариваетесь о стоимости перевозки и единой ценовой
                  политики пока нет. Стоимость может зависеть от срочности
                  доставки, потраченного времени путешественника на встречу с
                  вами в пунктах отправления и прибытия, габаритов и другого.
                  Так международная доставка документов при встрече
                  путешественника в аэропортах обоих стран/городов может
                  обойтись в сумму от 500 рублей на усмотрение обеих сторон.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4">
                  Планирую путешествие и готов взять с собой небольшую посылку
                  за вознаграждение, как мне это сделать?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body3" align="center">
                  <a href="https://t.me/whoflies_bot">
                    Запустите приложение Whoflies
                  </a>{" "}
                  и найдите заказ на отправку по вашему маршруту, спишитесь с
                  заказчиком и обговорите все детали - что именно нужно
                  доставить, стоимость, место встречи в точке отправления и
                  назначения и т.д. Если заказы отсутствуют, рекомендуем
                  опубликовать ваше путешествие и указать маршрут, даты, а также
                  что именно вы готовы взять с собой (например, документы или
                  небольшую посылку) и примерную стоимость перевозки, вам
                  обязательно напишут.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4">
                  Какие посылки категорически запрещено брать с собой в
                  путешествие?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body3" align="center">
                  1. В первую очередь мы <u>категорически не рекомендуем</u>{" "}
                  брать ничего запечатанного, будь то новый iPhone в подарок
                  другу, конверт с завещанием или пачка с рисом. Как бы вас не
                  просили и не пытались убедить, что в посылке нет ничего
                  запрещенного, показывали чеки свидетельствующие о покупке —
                  смело <b>отказывайтесь</b>, либо требуйте распаковки посылки и
                  детальный осмотр содержимого. Включайте роль злого
                  пограничника — это ваша жизнь и потенциальные проблемы не
                  стоят такого риска. <br /> <br />
                  2. Если у вас возникло хоть малейшее подозрение о том, что
                  "что-то не так" — <b>отказывайтесь</b> от перевозки, не
                  жалейте потраченного времени на переписку и встречу, вам не
                  должно быть неудобно — это ваша жизнь. <br /> <br />
                  3. Обязательно изучите правила вывоза и ввоза в странах
                  отправления и прибытия, а также внутренние правила перевозки
                  авиакомпании. Очень часто выясняются совершенно неожиданные
                  факты, так, например, в Турции запрещены к ввозу Iqos, а в
                  Таиланде любые вейпы. Мы уже работаем над отдельным разделом с
                  перечнем разрешенного и запрещенного для ввоза по странам.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4">
                  Сколько я могу заработать доставив посылку или документы?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body3" align="center">
                  Вы сами устанавливаете стоимость перевозки. Рекомендуем
                  учитывать характер груза (такие как габариты и вес), издержки
                  на встречи с отправителем и его представителем в точках
                  отправления и назначения.
                  <br />
                  <br />
                  Так, например, если вам подвезли небольшую пачку документов в
                  аэропорт или доставили курьером домой в стране отправления и
                  встретили в аэропорту в стране назначения, то рекомендуем
                  взымать от 500 рублей.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4">
                  Стоит ли мне требовать предоплату с отправителя?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body3" align="center">
                  В связи с участившимися случаями мошенничества мы настоятельно
                  не рекомендуем брать с заказчика предоплату и рассчитываться
                  только при передачи посылки из рук в руки в точке назначения.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </CustomTabPanel>
        </Box>
      </Container>
    </div>
  );
}