import { Container, Grid, Typography } from "@mui/material";
import { FaTelegram, FaSearch } from "react-icons/fa";
import { FaCirclePlus, FaRegMessage } from "react-icons/fa6";
const HowItWorks = () => {
  return (
    <div id="how-whoflies-works" className="howitworks-container">
      <Container maxWidth="lg">
        <Typography variant="h2">Как это работает?</Typography>
        <Grid container spacing={4} justifyContent="center">
          <Grid
            item
            lg={3}
            xs={10}
            style={{
              marginBottom: "20px",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <FaTelegram size={50} color="#5DB075" />
            <Typography variant="h3" align="center">
              Запустите приложение
            </Typography>
            <Typography variant="body3" align="center">
              Начните работу с приложением{" "}
              перейдя по <a href="https://t.me/whoflies_bot">ссылке</a> или
              набрав <i>@whoflies_bot</i> в поиске Telegram
            </Typography>
          </Grid>
          <Grid
            item
            lg={3}
            xs={10}
            style={{
              marginBottom: "20px",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <FaSearch size={50} color="#5DB075" />
            <Typography variant="h3" align="center">
              Воспользуйтесь поиском
            </Typography>
            <Typography variant="body3" align="center">
              Находите объявления от путешественников и заказчиков,
              воспользуйтесь поиском по месту отправления и прибытия
            </Typography>
          </Grid>
          <Grid
            item
            lg={3}
            xs={10}
            style={{
              marginBottom: "20px",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <FaCirclePlus size={50} color="#5DB075" />
            <Typography variant="h3" align="center">
              Создайте объявление
            </Typography>
            <Typography variant="body3" align="center">
              Если не удалось найти подходящее путешествие или заказ, просто
              создайте новое объявление
            </Typography>
          </Grid>
          <Grid
            item
            lg={3}
            xs={10}
            style={{
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <FaRegMessage size={50} color="#5DB075" />
            <Typography
              variant="h3"
              align="center"
              sx={{
                marginLeft: { lg: "30px" },
                marginRight: { lg: "30px" },
              }}
            >
              Обсудите условия{" "}
            </Typography>
            <Typography variant="body3" align="center">
              Связывайтесь с заказчиком или путешественником напрямую в Телеграм
              и договаривайтесь об условиях
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default HowItWorks;
